import React, {PureComponent, Fragment} from 'react';
import PublicHeader from './../../components/header/index';
import Action from './../action';
import './index.scss';
import {connect} from 'react-redux';
import {getPayParams} from './store/actionCreators';
import {Toast, Radio, List} from 'antd-mobile';
import history from './../../utils/history';
import {Icon} from 'antd';
import '../../utils/storage';

const RadioItem = Radio.RadioItem;


class PayOrder extends PureComponent {
    state = {
        value: 3,
    };

    async handlePayOrder() {
        const {orderId = 0} = this.props.location.state;

        const {value} = this.state;

        console.log("props", this.props)
        console.log(this.state)
        let storage = Storage.Base.getInstance();
        //token = storage.get("userInfo").Token,
        //   openId = storage.get("oauthInfo").OpenId;

        let data = {};//encodeURIComponent(`${token}|${OrderAmount}|${openId}|${SONumber}|${OrderNumber}|${ProviderId}`);

        console.log('data', data);

        // 支付方式，0，微信支付|1.会员支付
        var payType = value;

        const result = await this.props.handleWechatPay(data, payType, orderId);

        console.log("payResult", result);
        if (result.status === 200 && result.data.data) {
            if (payType == 1) {
                Toast.success('支付成功');
                history.push('/paySuccess');
            } else {
                window.WeChatPay.Base.getInstance().WeChatPay(result.data.Data, this.HandlePayCallBack);
            }
        } else {
            Toast.info("发起支付异常!");
        }
    }

    HandlePayCallBack(res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
            Toast.success('支付成功');
            history.push('/paySuccess');
        }
        else {
            Toast.success('支付已取消!');
        }
    }

    onChange = (value) => {
        console.log("onChange", value)
        this.setState({
            value,
        });
    };

    render() {
        const {value} = this.state;
        const {state} = this.props.location;

        const {orderId, price} = state;

        console.log(orderId);
        console.log(price);
        const data = [
            {value: 3, label: '微信支付'},
            {value: 1, label: '会员卡支付'},
        ];
        return (
            <Fragment>
                <PublicHeader title="收银台"/>

                <List>
                    {data.map(i => (
                        <RadioItem key={i.value} checked={value === i.value} onChange={() => this.onChange(i.value)}>
                            {i.label}
                        </RadioItem>
                    ))}
                </List>

                <Action text="付款" price={price / 100} HandleSubmitOrder={() => {
                    this.handlePayOrder()
                }}/>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleWechatPay: (data, payType, orderId) => dispatch(getPayParams({
            payType: payType,
            orderId: orderId,
            payMethod: 'WeChatJs',
        }))
    }
};

export default connect(null, mapDispatchToProps)(PayOrder);
