import React, {PureComponent} from 'react';
import eventProxy from 'react-eventproxy';
import {TabBar} from 'antd-mobile';

import history from './../../utils/history';
import {getUrlParam} from './../../utils/common';

import 'react-bscroll/lib/react-scroll.css'

import Main from "./home/index";
import My from "../my/My";
import '../../utils/storage';
import {Icon} from 'antd';


const Data = [];
for (let i = 0; i < 10; i++) {
    Data.push(i)
}

class Home extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'greenTab',
            hidden: false,
            fullScreen: true,
            isSelected: false,
            listData: Data,
            childData: 666,
            currentPage: 1,
            calcHeight: 760,
            totalRecords: 0
        };

        eventProxy.on("targetHome", (object) => {
            this.setState({
                selectedTab: "yellowTab"
            });
        });
    }


    async loadMoreData(props) {
    }

    renderFactory(pageText) {
        switch (pageText) {
            case "MAIN":
                return (<Main top={true}/>);
            case "USER":
                return ("");
            default:
                return (<Main top={true}/>);
        }
    }


    renderContent(pageText) {
        if (pageText === "USER") {
            return (
                <div style={{backgroundColor: 'white', height: '100%', textAlign: 'center'}}>
                    {
                        this.renderFactory(pageText)
                    }
                </div>
            )
        }
        if (pageText === "MAIN") {
            return (
                <div id="containerMain" className="container">
                    {this.renderFactory(pageText)}
                </div>
            );
        }
    }

    userCenter() {
        this.setState({
            selectedTab: 'yellowTab',
            isSelected: true
        });

        let userInfo = Storage.Base.getInstance().get('userInfo');
        if (userInfo === null) {
            history.push('/home/login');
            return;
        }
        history.push('/home/my');
    }

    componentDidMount() {
        console.log('scrollObj', this.refs);

        this.setState({
            selectedTab: 'blueTab'
        });
        if (getUrlParam('tab') === "User") {
            this.setState({
                selectedTab: 'yellowTab'
            });
        }

        if (getUrlParam('tab') === "Space") {
            this.setState({
                selectedTab: 'greenTab'
            });
        }

        eventProxy.on('selectedTab', (item) => {
            this.setState({
                selectedTab: item
            });
        });
    }


    render() {
        return (
            <div style={this.state.fullScreen ? {
                position: 'fixed',
                height: '100%',
                width: '100%',
            } : {height: 400}}>
                <TabBar
                    unselectedTintColor="#949494"
                    tintColor="#33A3F4"
                    barTintColor="white"
                    hidden={this.state.hidden}>
                    <TabBar.Item
                        title="首页"
                        key="home"
                        icon={<Icon type="home"/>}
                        selectedIcon={<Icon type="home"/>}
                        selected={this.state.selectedTab === 'blueTab'}
                        onPress={() => {
                            this.setState({
                                selectedTab: 'blueTab',
                            });
                            history.push('/home?tab=Home');
                            eventProxy.trigger("showTop", false);
                        }}
                        data-seed="logId"
                    >
                        {this.renderContent('MAIN')}
                    </TabBar.Item>

                    <TabBar.Item
                        title="个人中心"
                        key="my"
                        icon={<Icon type="user"/>}
                        selectedIcon={<Icon type="user"/>}
                        selected={this.state.selectedTab === 'yellowTab'}
                        onPress={() => {
                            this.userCenter();
                        }}>
                        {this.renderContent('USER')}
                    </TabBar.Item>
                </TabBar>
            </div>
        )
    }
}

export default Home;