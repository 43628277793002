import Home from './pages/home/Index';
import Order from "./pages/order/Order";
import Pay from "./pages/pay/Pay";
import My from "./pages/my/My";
import Area from "./pages/area/Area";
import Login from "./pages/login/Login";
import MyCharge from "./pages/myCharge/Index";
import MyOrder from "./pages/myOrder/Index";
import PayOrder from './pages/payFor/index';
import PaySuccess from './pages/paySuccess/Index';
import PayFail from './pages/payFail/Index';
import ItemDetail from './pages/itemDetail/Index';


export default [

    {
        path: "/home",
        component: Home,
        exact: true,
        key: 'home'
    },
    {
        path: "/",
        component: Home,
        exact: true,
        key: 'index'
    },
    {
        path: "/home/my",
        component: My,
        exact: true,
        key: 'my'
    },
    {
        path: "/home/pay",
        component: Pay,
        exact: true,
        key: 'pay'
    },
    {
        path: "/home/order",
        component: Order,
        exact: true,
        key: 'order'
    },
    {
        path: "/area",
        component: Area,
        exact: true,
        key: 'area'
    },
    {
        path: "/home/login",
        component: Login,
        exact: true,
        key: 'login'
    },
    {
        path: "/home/myCharge",
        component: MyCharge,
        exact: true,
        key: 'myCharge'
    },
    {
        path: "/home/myOrder",
        component: MyOrder,
        exact: true,
        key: 'myOrder'
    },
    {
        path: "/home/payFor",
        component: PayOrder,
        exact: true,
        key: 'payOrder'
    },
    {
        path: "/home/paySuccess",
        component: PaySuccess,
        exact: true,
        key: 'paySuccess'
    },
    {
        path: "/home/payFail",
        component: PayFail,
        exact: true,
        key: 'payFail'
    },
    {
        path: "/home/itemDetail",
        component: ItemDetail,
        exact: true,
        key: 'itemDetail'
    }
];



