import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';
import {reducer as homeReducer} from '../pages/home/store';
import {reducer as userChargeReducer} from '../pages/myCharge/store';
import {reducer as userInfoReducer} from '../pages/my/store';
import {reducer as orderListReducer} from '../pages/myOrder/store';
import {reducer as createOrderReducer} from '../pages/order/store';
import {reducer as payReducer} from '../pages/pay/store';
import {reducer as itemInfoReducer} from '../pages/itemDetail/store';


const middlewares = [];
const reducer = combineReducers({
    // user: userReducer,
    home: homeReducer,
    userChargeList: userChargeReducer,
    userInfo: userInfoReducer,
    orderList: orderListReducer,
    createOrder: createOrderReducer,
    pay: payReducer,
    itemInfo: itemInfoReducer,
    // masters: mastersReducer,
    // bank: bankCardReducer,
    // withdraw: withdrawCardReducer,
    // cart: cartCardReducer,
    // community:communityReducer,
    // search:searchReducer,
    // releaseMaster:releaseMasterReducer,
});

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
// middlewares.push(logger);
middlewares.push(thunk);

const enhancer = composeEnhancers(applyMiddleware(...middlewares))
const store = createStore(reducer,
    enhancer
);
export default store;