import React, {PureComponent, Fragment} from 'react';
import './index.scss';
import {Grid, WhiteSpace, Toast, ListView, Button, Carousel} from 'antd-mobile';
import history from './../../../utils/history';
import {getIndexList, getCcbUrl, getUserLogin} from '../store/actionCreators';
import {connect} from 'react-redux';

import {IMAGE_URL} from './../../../utils/api';

class Main extends PureComponent {
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            indexData: [],
        };
    }

    componentDidMount() {
        this.props.getIndexList();
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 600);
    }

    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        this.setState({isLoading: true});
        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 1000);
    }

    handleNavUrl(id) {
        history.push("/home/itemDetail", {id: id});
    }

    async ccbUrl() {
        let userInfo = Storage.Base.getInstance().get('userInfo');
        if (userInfo === null) {
            history.push('/home/login');
            return;
        }

        // var result = await this.props.getUserLogin()
        // if(!result){
        //     Storage.Base.getInstance().remove("userInfo")
        //     history.push('/home/login');
        //     return;
        // }

        var result = await this.props.getCcbUrl();
        var url = result.data;
        window.location.href = url;
    }

    render() {
        const {indexList = []} = this.props;

        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 8,
                    borderTop: '1px solid #ECECED',
                    borderBottom: '1px solid #ECECED',
                }}
            />
        );

        let {dataSource} = this.state;
        let index = indexList.length - 1;
        const row = (rowData, sectionID, rowID) => {
            if (index < 0) {
                index = indexList.length - 1;
            }
            const obj = indexList[index--];
            return (
                <div key={rowID} style={{padding: '0 15px'}} data-id={obj.id}>
                    <div
                        style={{
                            lineHeight: '50px',
                            color: '#888',
                            fontSize: 18,
                            borderBottom: '1px solid #F6F6F6',
                        }}
                    >{obj.name}</div>
                    <div style={{display: '-webkit-box', display: 'flex', padding: '10px 0'}} onClick={() => {
                        this.handleNavUrl(obj.id)
                    }}>
                        <img style={{height: '105px', width: '105px', marginRight: '15px'}} src={obj.mainImage}
                             alt=""/>
                        <div style={{lineHeight: 1, width: '100%'}}>
                            <div style={{marginBottom: '8px', fontSize: "18px"}}>{obj.title}</div>
                            <div><span style={{fontSize: '30px', color: '#FF6E27'}}>{obj.price}</span>
                                <span style={{textDecoration: "line-through"}}>¥ {obj.price}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <div>
                <Carousel autoplay={false} infinite>
                    <img
                        src={IMAGE_URL + "/banner.jpg"}
                        alt="直销银行"
                        style={{width: '100%', height: '180%', verticalAlign: 'top'}}
                        onLoad={() => {
                            // fire window resize event to change height
                            window.dispatchEvent(new Event('resize'));
                            this.setState({imgHeight: 'auto'});
                        }}
                        onClick={() => {
                            this.ccbUrl()
                        }}
                    />
                </Carousel>
                <ListView
                    ref={el => this.lv = el}
                    dataSource={dataSource.cloneWithRows(indexList)}
                    renderHeader={() => <span>商品列表</span>}
                    renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : ''}
                    </div>)}
                    renderRow={row}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={4}
                    useBodyScroll
                    onScroll={() => {
                        console.log('scroll');
                    }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </div>
        );
    }
}

const mapStateToProps = (home) => {
    return {
        indexList: home.home.indexList
    }
};

const mapDispatchToProps = (dispatch) => ({
    getIndexList: data => dispatch(getIndexList()),
    getCcbUrl: () => dispatch(getCcbUrl()),
    getUserLogin: () => dispatch(getUserLogin())
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);