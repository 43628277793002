import React, {Component, PureComponent} from 'react';

import {connect} from 'react-redux';
import {createOrder, getAddress, getSchool, getGrade, getClasses} from './store/actionCreators';
import PublicHeader from './../../components/header';
import history from './../../utils/history';
import 'react-bscroll/lib/react-scroll.css'
import '../../utils/storage';
import {addressData} from '../../data/addressData';
import _ from 'lodash';
import './index.scss'
import {createForm} from 'rc-form';
import Action from './../action';

import {Badge, WhiteSpace, Toast, InputItem, Button, List, Picker} from 'antd-mobile';

const Item = List.Item;
const Brief = Item.Brief;

class Order extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            Provider: '',
            Phone: '',
            addressID: [],
            ShippingAddress: '',
            isDefault: false,
            addressInfo: [],
            schoolData: [],
            arrayGrade: [],
            arrayClasses: [],
            pId: '',
            cId: '',
            dId: '',
            sId: '',
            gId: '',
            classId: '',
            pName: '',
            cName: '',
            dName: '',
            sName: '',
            gName: '',
            name: '',
            phoneNum: '',
            schoolId: '',
            hasError: false,
            value: '',
            itemInfo: {},
        }

    }

    componentDidMount() {
        const itemInfo = this.props.location.state.itemInfo;
        this.setState({
            itemInfo: itemInfo,
        })
    }

    async addressChange(e) {
        const schoolList = await this.props.getSchool(e[2]);
        if (_.isEmpty(schoolList)) {
            return;
        }
        var schools = [];
        schoolList.map((item, index) => {
            var schoolDetail = {};
            schoolDetail.label = item.name;
            schoolDetail.value = item.id;
            schools.push(schoolDetail);
        })
        this.setState({
                schoolData: schools
            }
        )
        var defaultSchool = schoolList[0];
        if (defaultSchool) {
            this.schoolChange(defaultSchool.id);
        }
    }

    async schoolChange(schoolId) {
        if (_.isEmpty(schoolId)) {
            return;
        }
        const gradeList = await this.props.getGrade(schoolId)
        if (_.isEmpty(gradeList)) {
            return;
        }
        var grades = [];
        gradeList.map((item, index) => {
            var gradeDetail = {};
            gradeDetail.label = item.name;
            gradeDetail.value = item.id;
            grades.push(gradeDetail);
        })
        this.setState({
            arrayGrade: grades,//接数组
            schoolId: schoolId
        })
        var defaultGrade = gradeList[0];
        if (defaultGrade) {
            this.gradeChange(0, defaultGrade);
        }
    }

    async gradeChange(gradeId) {
        if (_.isEmpty(gradeId)) {
            return;
        }
        const classesList = await this.props.getClasses(gradeId)
        if (_.isEmpty(classesList)) {
            return;
        }
        var classes = [];
        classesList.map((item, index) => {
            var classDetail = {};
            classDetail.label = item.name;
            classDetail.value = item.id;
            classes.push(classDetail);
        })
        this.setState({
            arrayClasses: classes,//接数组
            classId: classesList[0].id,
            className: classesList[0].name,
        })
    }

    async classesChange(e) {
        if (_.isEmpty(e)) {
            return;
        }
        this.setData({
            classId: e.id,
            className: e.name,
        })
    }

    onErrorClick = () => {
        if (this.state.hasError) {
            Toast.info('请输入正确的电话号码！');
        }
    }
    onChange = (value) => {
        if (value.replace(/\s/g, '').length < 11) {
            this.setState({
                hasError: true,
            });
        } else {
            this.setState({
                hasError: false,
            });
        }
        this.setState({
            value,
        });
    }

    validateAccount = (rule, value, callback) => {
        if (value && value.length > 4) {
            callback();
        } else {
            callback(new Error('不能为空'));
        }
    }

    async payOrder() {
        let userInfo = Storage.Base.getInstance().get('userInfo');
        if (userInfo === null) {
            history.push('/home/login');
            return;
        }

        Toast.info("系统升级中，敬请期待～")
    }

    render() {
        const {getFieldProps, getFieldError} = this.props.form;
        const itemInfo = this.state.itemInfo;
        return (
            <div>
                <PublicHeader title="确认订单" bgColor="#51B5E7"/>
                <List style={{backgroundColor: 'white'}} className="picker-list" renderHeader={() => '基本信息'}>
                    <Picker
                        extra="请选择"
                        data={addressData}
                        title="选择地区"
                        {...getFieldProps('addressData')}
                        onOk={e => {
                            this.setState({addressID: e});
                            this.addressChange(e);
                        }}>
                        <List.Item arrow="horizontal">地区</List.Item>
                    </Picker>

                    <Picker data={this.state.schoolData} cols={1} {...getFieldProps('name')}
                            className="forss"
                            onChange={(v) => {
                                console.log(v)
                                this.schoolChange(v)
                            }}>
                        <List.Item arrow="horizontal">学校</List.Item>
                    </Picker>
                    <Picker data={this.state.arrayGrade} cols={1} {...getFieldProps('name')}
                            className="forss"
                            onChange={(v) => {
                                this.gradeChange(v)
                            }}>
                        <List.Item arrow="horizontal">年级</List.Item>
                    </Picker>
                    <Picker data={this.state.arrayClasses} cols={1} {...getFieldProps('name')}
                            className="forss"
                            onChange={(v) => {
                                this.classesChange(v)
                            }}>
                        <List.Item arrow="horizontal">班级</List.Item>
                    </Picker>
                    <InputItem
                        {...getFieldProps('focus')}
                        clear
                        placeholder="请输入姓名"
                        ref={el => this.inputRef = el}
                    >姓名</InputItem>
                    <InputItem
                        type="phone"
                        placeholder="请输入手机号码"
                        error={this.state.hasError}
                        onErrorClick={this.onErrorClick}
                        onChange={this.onChange}
                        value={this.state.value}
                    >手机号码</InputItem>
                </List>

                <List renderHeader={() => '商品信息'} className="my-list">
                    <Item extra={itemInfo.num}
                          align="top"
                          thumb={itemInfo.mainImage}
                          multipleLine>
                        {itemInfo.title} <Brief>¥{itemInfo.price} <Badge text="惠" hot style={{marginLeft: 12}}/></Brief>
                    </Item>
                </List>
                <List className="my-list">
                    <InputItem
                        {...getFieldProps('account', {
                            rules: [
                                {required: true, message: '请输入优惠码'},
                                {validator: this.validateAccount},
                            ],
                        })}
                        clear
                        error={!!getFieldError('account')}
                        onErrorClick={() => {
                            alert(getFieldError('account').join('、'));
                        }}
                        placeholder="请输入优惠码"
                    >优惠码</InputItem>

                    <Item extra={
                        <span style={{color: "red"}}>¥0</span>
                    }>优惠金额</Item>
                    <Item extra={
                        <span style={{color: "red"}}>¥{itemInfo.price}</span>
                    }>支付金额</Item>
                </List>
                <Action text="立即支付" price={itemInfo.price} HandleSubmitOrder={() => {
                    this.payOrder()
                }}/>
            </div>
        )
    }
}

const mapStateToProps = (data) => {
    return {
        createOrder: data,
        addressList: data.addressList,
        itemInfo: data.itemInfo.itemInfo
    }
};

const mapDispatchToProps = (dispatch) => ({
    createOrder: (param) => dispatch(createOrder(param)),
    getSchool: (districtId) => dispatch(getSchool({districtId: districtId})),
    getGrade: (schoolId) => dispatch(getGrade({schoolId: schoolId})),
    getClasses: (gradeId) => dispatch(getClasses({gradeId: gradeId})),
});

const OrderWrapperedAndAddress = createForm()(Order);

export default connect(mapStateToProps, mapDispatchToProps)(OrderWrapperedAndAddress);