import React, {PureComponent, Fragment} from 'react';
import {formatDate} from '../../utils/common';

import {Picker, List, ListView, Grid, Tabs, Icon, NoticeBar, Toast, Checkbox} from 'antd-mobile';

import PublicHeader from './../../components/header/index';

import {connect} from 'react-redux';
import {payment, getOrderDetail} from './store/actionCreators';
import history from '../../utils/history';
import Action from './../action';

const Item = List.Item;
const Brief = Item.Brief;


class Pay extends PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {orderId = ''} = this.props.location.state;
        this.props.getOrderDetail(orderId);
    }

    async payOrder() {
        let userInfo = Storage.Base.getInstance().get('userInfo');
        if (userInfo === null) {
            history.push('./oauth');
            return;
        }
        if (userInfo && !userInfo.register) {
            history.push('/bind');
            console.log('去绑定手机');
        }

        const {orderId = 0} = this.props.location.state;
        const orderDetail = this.props.orderDetail;

        const price = orderDetail.price;
        console.log('orderId', orderId);
        history.push("/payFor", {orderId, price});
    }

    render() {
        const orderDetail = this.props.orderDetail;
        const {venueDTO = {}, siteDTO = {}, memberDTO = {}} = orderDetail;
        return (
            <Fragment>
                <PublicHeader title="订单详情"/>
                <Fragment>
                    <List className="my-list">
                        <Item multipleLine>
                            订单编号： <Brief>{orderDetail.code}</Brief>
                        </Item>
                        <Item multipleLine>
                            订单状态： <Brief>{orderDetail.status == 0 ? "待支付" : "已支付"}</Brief>
                        </Item>
                        <Item multipleLine>
                            预订场馆： <Brief>{venueDTO.name}</Brief>
                        </Item>
                        <Item multipleLine>
                            预订场地： <Brief>{siteDTO.name}</Brief>
                        </Item>
                        <Item multipleLine>
                            预订时间： <Brief>{formatDate(orderDetail.beginTime)}</Brief>
                        </Item>
                        <Item multipleLine>
                            预订人： <Brief>{memberDTO.name}</Brief>
                        </Item>
                    </List>
                </Fragment>
                <Action text="立即支付" price={orderDetail.price / 100} HandleSubmitOrder={() => {
                    this.payOrder()
                }}/>
            </Fragment>
        )
    }
}

const mapStateToProps = (data) => {
    return {
        orderDetail: data.pay.orderDetail
    }
};

const mapDispatchToProps = (dispatch) => ({
    getOrderDetail: (param) => dispatch(getOrderDetail(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pay);