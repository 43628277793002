import * as constants from './constants';

const defaultState = {
    payment: {},
    orderDetail: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.PAYMENT:
            return {...state, payment: action.value}
        case constants.ORDER_QUERY:
            return {...state, orderDetail: action.value}
        default:
            return state;
    }
}