import * as constants from './constants';

const defaultState = {
    orderList: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.ORDER_LIST:
            return {...state, orderList: action.value}
        default:
            return state;
    }
}