import React, {Component, PureComponent} from 'react';
import {List} from 'antd-mobile';
import {Icon} from 'antd';
import "./index.scss"
import history from './../../utils/history';
import {getUserInfo, isRegisterFun} from './store/actionCreators';
import {connect} from 'react-redux';
import PublicHeader from './../../components/header/index';

const Item = List.Item;

class My extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {}
        }
    }

    componentDidMount() {
        let userInfo = Storage.Base.getInstance().get('userInfo');
        if (userInfo === null) {
            history.push('/home/login');
            return;
        }
        this.setState({
            userInfo: userInfo
        })
    }

    handleNavUrl(url) {
        history.push(url, {customerDetail: "/home/my"});
    }

    render() {
        const userInfo = this.state.userInfo;
        const {name = ''} = userInfo;
        return (
            <div>
                <PublicHeader title="个人中心" bgColor="#51B5E7"/>
                <List renderHeader={() => '基本信息'} className="my-list">
                    <Item
                        thumb={<Icon type="user" style={{fontSize: '66px', color: '#08c'}}/>}
                        multipleLine
                        extra="">
                        {name}
                    </Item>
                </List>

                <List renderHeader={() => '其他信息'} className="my-list">
                    <Item
                        thumb={<Icon type="bars" style={{fontSize: '26px', color: '#08c'}}/>}
                        onClick={() => {
                            this.handleNavUrl("/home/myOrder")
                        }}
                        arrow="horizontal"
                    >
                        我的订单
                    </Item>
                </List>
            </div>
        )
    }


}

const mapStateToProps = (userInfo) => {
    return {
        userInfo: userInfo.userInfo.userInfo
    }
};

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: (id) => dispatch(getUserInfo({userId: id})),
    isRegisterFun: (param) => dispatch(isRegisterFun(param))
});

export default connect(mapStateToProps, mapDispatchToProps)(My);

