import React, {Component, Fragment} from 'react';

import { Result, Icon, WhiteSpace } from 'antd-mobile';
import PublicHeader from './../../components/header/index';

class PayFail extends Component {

    render() {
        return (
            <Fragment>
                <PublicHeader title="支付失败"/>
                <div className="sub-title">支付失败</div>
                <Result
                    img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
                    title="支付失败"
                    message="所选银行卡余额不足"
                />
            </Fragment>
        );
    }
}

export default PayFail;
