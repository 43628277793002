import * as constants from './constants';
import {post} from "../../../utils/request";
import history from '../../../utils/history';
import {Toast} from 'antd-mobile';
import axios from 'axios';
import {API_PAY} from '../../../utils/api';



export const getPayParams = (params) => {
    return async (dispatch) => {
        const result = await getWechatParams(params);
        return result;
    }
};

const getWechatParams = (params) => {
    return axios({
        method: 'post',
        url: API_PAY,
        data: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        timeout: 3000
    })
        .then(response => {
            return response;
        });
};
