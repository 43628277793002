import * as constants from './constants';
import {get,post} from "../../../utils/request";
import {USER_RECHARGE_LIST} from '../../../utils/api';

export const ChargeList = (Entity) => ({
    type: constants.USER_CHARGE_LIST,
    value: Entity
});

export const getUserChargeList = (params) => {
    return (dispatch) => {
        return post(USER_RECHARGE_LIST, params)
            .then((response) => {
                return dispatch(ChargeList(response.data));
            });
    }
};

