import * as constants from './constants';
import {post, get} from '../../../utils/request';

import {ITEM_INFO} from '../../../utils/api';


const ItemInfo = (DataList) => ({
    type: constants.ITEM_INFO,
    value: DataList
});


export const itemInfo = (id) => {
    return (dispatch) => {
        return get(ITEM_INFO + "/" + id, id)
            .then((response) => {
                dispatch(ItemInfo(response.data.result));
            })
    }
}

