import React, {PureComponent} from 'react';
import PublicHeader from './../../components/header';
import history from './../../utils/history';
import 'react-bscroll/lib/react-scroll.css'
import './index.scss'
import '../../utils/storage';
import {Grid, WhiteSpace, Toast, ListView, WingBlank, Carousel} from 'antd-mobile';

import {itemInfo} from './store/actionCreators';

import {connect} from 'react-redux';

const Data = [];
for (let i = 0; i < 10; i++) {
    Data.push(i)
}

class ItemDetail extends PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const itemId = this.props.location.state.id;
        console.log("itemId", itemId)
        this.props.getItemInfo(itemId);
    }

    handleBuy = () => {
        const {itemInfo = {}} = this.props;
        history.push("/home/order", {itemInfo: itemInfo})
    };

    productDetail() {
        const {itemInfo = {}} = this.props;
        var html = {__html: itemInfo.detail};
        return (
            <div dangerouslySetInnerHTML={html}></div>
        )
    }

    render() {
        const {itemInfo = {}} = this.props;
        const {images = []} = itemInfo;
        return (
            <>
            <PublicHeader title="商品详情" bgColor="#51B5E7"/>
            <div className="art-product-shop">
                <Carousel autoplay={false}
                          infinite
                          dotStyle={{
                              width: '15px',
                              height: '15px',
                              backgroundColor: '#999999',
                              marginBottom: '20px'
                          }}
                          dotActiveStyle={{
                              width: '15px',
                              height: '15px',
                              backgroundColor: '#ffffff',
                              marginBottom: '20px'
                          }}
                >
                    {images.map(val => (
                        <a style={{display: 'inline-block', width: '100%', height: "auto"}} key={val}>
                            <img
                                src={val}
                                alt="直销银行"
                                style={{
                                    width: '100%',
                                    height: "380px"
                                }}
                                onLoad={() => {
                                    // fire window resize event to change height
                                    window.dispatchEvent(new Event('resize'));
                                    this.setState({imgHeight: 'auto'});
                                }}
                            />
                        </a>
                    ))}
                </Carousel>
                <div
                    className="art-product-shop__detail">
                    <h4>{itemInfo.title}</h4>
                    <p className="art-product-shop__detail-font">
                        <span>现价:</span>
                        <i>￥</i>
                        <i>{itemInfo.price}</i>
                        <span style={{textDecoration: "line-through"}}>￥{itemInfo.price}</span>
                    </p>
                </div>
                <div className="art-product-shop__free">
                    <div>运费</div>
                    <div>包邮</div>
                </div>

                <div className="art-product-shop__space"></div>

                <div className="art-product-shop__homepage">
                    <div className="art-product-shop__homepage__detail">
                        详情
                    </div>
                </div>

                <div className="art-product-shop__homepage__content">
                    <div>{this.productDetail()}</div>
                </div>

                <div className="art-product-shop__space"></div>

            </div>
            <div className="art-product-shop__comment-whiteSpace"></div>

            <div className="detail_footer">
                <div className="detail_footer_btn">
                    <div className="text"
                         onClick={() => {
                             this.handleBuy()
                         }}>
                        立即购买
                    </div>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (itemInfo) => {
    return {
        itemInfo: itemInfo.itemInfo.itemInfo
    }
};

const mapDispatchToProps = (dispatch) => ({
    getItemInfo: (id) => dispatch(itemInfo(id)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ItemDetail);