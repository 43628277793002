export const DEVHOST = 'http://lohost:8080/mall';
export const MOOCHOST = 'http://yapi.demo.qunar.com/mock/65279/';
export const APIURL = 'http://artapi.cfyishudj.com/gateway?format=json';
export const PICTUREURL = 'http://res.cfyishudj.com/icon/';
export const SERVICEPICTUREURL = 'http://res.cfyishudj.com';
export const PRODUCTURL = 'http://typay.xiaohui.top';
export const DevEnv = true;
export const HOST = DevEnv ? DEVHOST : MOOCHOST;
export const API_USER_INFO = `${HOST}/api/v1/user/update`;
export const IMGURL = 'http://localhost:3000';
export const PRODIMGURL = 'http://res.cfyishudj.com';

export const COMMON_URL = 'http://typay.xiaohui.top/wap';


export const ITEM_LIST = `${COMMON_URL}/api/item/list`;
export const ITEM_INFO = `${COMMON_URL}/api/item/getDetail`;

export const INDEX_API = `${COMMON_URL}/api/mall/getMallInfo`;
export const VENUE_INFO = `${COMMON_URL}/api/mall/venue`;
export const SITE_LIST = `${COMMON_URL}/api/mall/site`;
export const CCB_URL = `${COMMON_URL}/api/ccb/url`;

export const ORDER_LIST = `${COMMON_URL}/api/order/myOrder`;
export const ORDER_CREATE = `${COMMON_URL}/api/order/create`;
export const ORDER_QUERY = `${COMMON_URL}/api/order/query`;

export const ADDRESS_LIST = `${COMMON_URL}/api/address/city/districts`;
export const SCHOOL_LIST = `${COMMON_URL}/api/school/list`;
export const GRADE_LIST = `${COMMON_URL}/api/school/grade`;
export const CLASS_LIST = `${COMMON_URL}api/school/classes`;


export const PAYMENT_URL = `${COMMON_URL}/api/order/payment`;
export const API_PAY = `${COMMON_URL}/api/order/payRequest`;


export const SPACE_LIST = `${COMMON_URL}/api/user/spaceList`;

export const USER_LOGIN = `${COMMON_URL}/api/user/login`;
export const OAUTH_URL = `${COMMON_URL}/api/user/oauth`;
export const USER_ACCESS_TOKEN = `${COMMON_URL}/api/user/accessToken`;

export const LOGIN = `${COMMON_URL}/api/user/userLogin`;

export const USER_REGISTER = `${COMMON_URL}/api/user/isRegister`;

export const USER_IS_LOGIN = `${COMMON_URL}/api/user/isLogin`;


export const USER_SMS_CODE = `${COMMON_URL}/api/user/smsCode`;


export const USER_BIND_PHONE = `${COMMON_URL}/api/user/phoneLogin`;
export const USER_INFO = `${COMMON_URL}/api/user/getUserInfo`;
export const USER_RECHARGE_LIST = `${COMMON_URL}/api/user/getUserRechargeList`;

export const IMAGE_URL = `http://typay.xiaohui.top/image`




