import * as constants from './constants';
import {get, post} from "../../../utils/request";
import {USER_SMS_CODE,USER_BIND_PHONE} from '../../../utils/api';

export const OrderDetail = (Entity) => ({
    type: constants.ORDER_QUERY,
    value: Entity
});

export const Payment = (Entity) => ({
    type: constants.PAYMENT,
    value: Entity
});


export const sendMessageActionResult = (params) => {
    return async (dispatch) => {
        const result = await post(USER_SMS_CODE, params);
        return result;
    }
}

export const bindPhoneActionResult = (params) => {
    return async (dispatch) => {
        const result = await post(USER_BIND_PHONE, params);
        return result;
    }
}
