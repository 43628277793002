import * as constants from './constants';

const defaultState = {
    createOrder: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.CREATE_ORDER:
            return {...state, createOrder: action.value}
        default:
            return state;
    }
}