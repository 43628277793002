import * as constants from './constants';
import {post, get} from "../../../utils/request";
import {ORDER_CREATE, ADDRESS_LIST, SCHOOL_LIST, GRADE_LIST, CLASS_LIST} from '../../../utils/api';

export const CreateOrder = (Entity) => ({
    type: constants.CREATE_ORDER,
    value: Entity
});

export const Address = (Entity) => ({
    type: constants.ADDRESS_LIST,
    value: Entity
});

export const createOrder = (params) => {
    return (dispatch) => {
        return post(ORDER_CREATE, params)
            .then((response) => {
                return dispatch(CreateOrder(response.data));
            });
    }
};


export const getAddress = (cityId) => {
    return (dispatch) => {
        return get(ADDRESS_LIST + "?cityId=" + cityId, "cityId")
            .then((response) => {
                return dispatch(Address(response.data));
            });
    }
};

export const getSchool = (param) => {
    return async (dispatch) => {
        const result = await get(SCHOOL_LIST + "?districtId=" + param.districtId, param);
        return result.data;
    }
}

export const getGrade = (param) => {
    return async (dispatch) => {
        const result = await get(GRADE_LIST + "?schoolId=" + param.schoolId, param);
        return result.data;
    }
}

export const getClasses = (param) => {
    return async (dispatch) => {
        const result = await get(CLASS_LIST + "?gradeId=" + param.gradeId, param);
        return result.data;
    }
}
