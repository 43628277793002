import axios from 'axios';

const request = function (servicekey, jsonobjparam, method) {

    return new Promise((resolve, reject) => {
        axios({
            method,
            url: servicekey,
            data: JSON.stringify(jsonobjparam),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            timeout: 20000
        }).then(response => {

            if (response.status === 200) {
                if (response.data) {
                    resolve(response.data)
                }
            }
        }, err => {
            if (err.Cancel) {
                console.log(err)
            } else {
                reject(err)
            }
        }).catch(err => {
            reject(err)
        })
    })
};

const post = (servicekey, params) => {
    return request(servicekey, params, 'post')
};

const get = (servicekey, params) => {
    return request(servicekey, params, 'get')
};

export {post, get}

  