import * as constants from './constants';
import {get,post} from "../../../utils/request";
import {USER_INFO,USER_REGISTER} from '../../../utils/api';

export const UserInfo = (Entity) => ({
    type: constants.USER_INFO,
    value: Entity
});

export const RegisterFun = (Entity) => ({
    type: constants.IS_Register,
    value: Entity
});

export const getUserInfo = (params) => {
    return (dispatch) => {
        return post(USER_INFO, params)
            .then((response) => {
                return dispatch(UserInfo(response.data));
            });
    }
};

export const isRegisterFun = (params) => {
    return (dispatch) => {
        return post(USER_REGISTER, params)
            .then((response) => {
                return dispatch(RegisterFun(response.data));
            });
    }
}
