import React, {PureComponent} from 'react';

import {Button, InputItem, List, PlaceHolder, Toast, WhiteSpace, WingBlank} from 'antd-mobile';
import {connect} from 'react-redux';
import {createForm} from 'rc-form';
import PublicHeader from './../../components/header';
import history from './../../utils/history';

import './index.scss'
import {bindPhoneActionResult, sendMessageActionResult,} from './store/actionCreators';

class Login extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            value: '',
            sendMessage: '获取验证码',
            time: 10,
            sendStatus: 0,
        }
        this.bindEvent();
    }

    componentDidMount() {
        let userInfo = Storage.Base.getInstance().get('userInfo');
        if (userInfo != null) {
            history.push('/home');
            return;
        }

    }

    bindEvent() {
        this.userLogin = this.userLogin.bind(this);
        this.handleSendCode = this.handleSendCode.bind(this);
    }


    onErrorClick = () => {
        if (this.state.hasError) {
            Toast.info('请输入正确的手机号！');
        }
    }
    onChange = (value) => {
        if (value.replace(/\s/g, '').length < 11) {
            this.setState({
                hasError: true,
            });
        } else {
            this.setState({
                hasError: false,
            });
        }
        this.setState({
            value,
        });
    }

    async handleSendCode() {
        var time = 60;
        var that = this;
        if (this.state.sendStatus === 1) {
            return;
        }

        var smsPhone = this.phoneRef.state.value;
        const data = {
            type: '3',
            phone: smsPhone.replace(/\s*/g, ""),
        };

        if (!data.phone) {
            Toast.info("请输入手机号码！")
            return;
        }

        const result = await this.props.handleSendCode(data);

        console.log("result", result)
        if (result.status === 200) {
            Toast.success("发送成功");
        } else {
            Toast.fail('网络异常', 1);
        }

        setInterval(function () {
            if (time <= 0) {
                that.setState({
                    sendMessage: '发送验证码',
                })
            }
            else {
                that.setState({
                    sendMessage: (time) + "重新发送",
                    sendStatus: 1
                });
                time--;
            }
        }, 1000);
    }


    async userLogin() {
        const data = {
            type: '2',
            phone: this.phoneRef.state.value.replace(/\s*/g, ""),
            smsCode: this.validateRef.state.value,
        };
        const result = await this.props.userLogin(data);
        console.log("bind result", result)
        if (result.status === 200) {
            const userInfo = result.data;
            Storage.Base.getInstance().set("userInfo", userInfo);
            setTimeout(() => {
                history.push('/home?tab=Home');
            }, 1000);
        } else {
            Toast.info("登录失败,请刷新重试.");
        }
    }

    render() {
        const {getFieldError} = this.props.form;
        const {sendMessage} = this.state;

        return (
            <div>
                <PublicHeader title="登录" bgColor="#51B5E7"/>
                <WingBlank size="md">
                    <div style={{marginTop: "30px"}}>
                        <List
                            renderHeader={() => '用户登录'}
                            renderFooter={() => getFieldError('account') && getFieldError('account').join(',')}
                        >
                            <InputItem
                                type="phone"
                                clear
                                placeholder="请输入手机号"
                                ref={el => this.phoneRef = el}
                                error={this.state.hasError}
                                onErrorClick={this.onErrorClick}
                                onChange={this.onChange}
                                value={this.state.value}
                            />

                            <div className="input-container" style={{display: "inline-flex"}}>
                                <InputItem placeholder="请输入验证码" ref={el => this.validateRef = el} maxLength="6"/>
                                <Button type="primary"
                                        style={{float: "right", textAlign: "center", width: "150px   "}}
                                        ref={el => this.sendRef = el} onClick={this.handleSendCode}>
                                    {sendMessage}
                                </Button>
                            </div>

                            <WhiteSpace/>
                            <Button type="primary" style={{background: "#E87908"}}
                                    onClick={this.userLogin}>立即登录</Button>
                            <WhiteSpace/>
                        </List>

                        <div className="quick-login">
                            <h4 className="txt-otherLogin">其他登录方式</h4>
                            <div className="quick-type">
                                <a className="J_ping quick-wx txt-wechat icon-mlogin icon-wx">微信</a>
                            </div>
                        </div>
                    </div>
                </WingBlank>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    userLogin: data => dispatch(bindPhoneActionResult(data)),
    handleSendCode: data => dispatch(sendMessageActionResult(data)),
});

const LoginWapperForm = createForm()(Login);

export default connect(null, mapDispatchToProps)(LoginWapperForm);
