import * as constants from './constants';
import {post, get, testRequest} from '../../../utils/request';
import {CCB_URL, ITEM_LIST, USER_IS_LOGIN} from '../../../utils/api';


const indexList = (DataList) => ({
    type: constants.INDEX_LIST,
    value: DataList
});

export const getIndexList = () => {
    return (dispatch) => {
        return get(ITEM_LIST, {})
            .then((response) => {
                dispatch(indexList(response.data.result));
            })
    }
}

export const getCcbUrl = () => {
    return async (dispatch) => {
        const result = await get(CCB_URL, {});
        return result;
    }
}

export const getUserLogin = () => {
    return async (dispatch) => {
        const result = await get(USER_IS_LOGIN, {});
        return result;
    }
}
