import * as constants from './constants';
import {get, post} from "../../../utils/request";
import {PAYMENT_URL, ORDER_QUERY} from '../../../utils/api';

export const OrderDetail = (Entity) => ({
    type: constants.ORDER_QUERY,
    value: Entity
});

export const Payment = (Entity) => ({
    type: constants.PAYMENT,
    value: Entity
});

export const getOrderDetail = (params) => {
    return (dispatch) => {
        return get(ORDER_QUERY + "/" + params, params)
            .then((response) => {
                return dispatch(OrderDetail(response.data));
            });
    }
};


export const payment = (params) => {
    return (dispatch) => {
        return post(PAYMENT_URL, params)
            .then((response) => {
                return dispatch(Payment(response.data));
            });
    }
};

