import React, {Component, Fragment} from 'react';

import {Result, Icon, WhiteSpace, Button} from 'antd-mobile';

import PublicHeader from './../../components/header/index';
import './index.scss'
import history from './../../utils/history';


class PaySuccess extends Component {

    HandleRouterHome() {
        history.push('/home?tab=User');
    }

    render() {
        return (
            <Fragment>
                <PublicHeader title="支付成功"/>
                <Result
                    img={<Icon type="check-circle" className="spe" style={{fill: '#1F90E6'}}/>}
                    title="支付成功"
                    message="恭喜您，预订成功！"
                />
                <div className="art-pay__return" onClick={this.HandleRouterHome.bind(this)}>
                    返回个人中心
                </div>
            </Fragment>
        );
    }
}

export default PaySuccess;
