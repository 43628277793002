import React, {Component, Fragment, PureComponent} from 'react';
import {ListView} from 'antd-mobile';
import PublicHeader from './../../components/header/index';
import {connect} from 'react-redux';
import './index.scss';
import history from './../../utils/history';

import {formatDate} from '../../utils/common';

import {getUserChargeList} from './store/actionCreators';

const NUM_ROWS = 10;
let pageIndex = 0;

function genData(pIndex = 0) {
    const dataBlob = {};
    for (let i = 0; i < NUM_ROWS; i++) {
        const ii = (pIndex * NUM_ROWS) + i;
        dataBlob[`${ii}`] = `row - ${ii}`;
    }
    return dataBlob;
}

class MyCharge extends PureComponent {

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.state = {
            dataSource,
            isLoading: true,
        }
    }

    componentDidMount() {
        let userInfos = Storage.Base.getInstance().get('userInfo');
        if (userInfos === null) {
            history.push('./oauth');
            return;
        }
        this.props.getUserChargeList(pageIndex, userInfos.id);

        setTimeout(() => {
            this.rData = genData();
            this.setState({
                dataSource: this.state.dataSource.cloneWithRows(this.rData),
                isLoading: false,
            });
        }, 600);
    }

    onEndReached = (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        const {orderList} = this.props;
        const {data = [{}], total} = orderList;

        if (data.length >= total) {
            this.setState({hasMore: false});
        } else {
            setTimeout(() => {
                this.setState({hasMore: false}, () => {
                    this.currentPage = ++this.currentPage;
                    this.props.getUserChargeList(pageIndex).then(() => {
                        this.setState({hasMore: true});
                    });
                });
            }, 200);
        }
    }

    render() {
        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 8,
                    borderTop: '1px solid #ECECED',
                    borderBottom: '1px solid #ECECED',
                }}
            />
        );

        const {userChargeList = [], renderRow} = this.props;
        const {data = [{}]} = userChargeList;
        let {dataSource} = this.state;

        let index = data.length - 1;
        const row = (rowData, sectionID, rowID) => {
            if (JSON.stringify(rowData) == "{}") {
                return (<div>
                    <div class="user-recharge" style={{textAlign: "center", padding: "15px", color: "#888"}}>
                        暂无充值信息
                    </div>
                </div>);
            }
            if (index < 0) {
                index = data.length - 1;
            }
            const obj = data[index--];
            return (
                <div key={rowID} style={{padding: '0 15px'}} data-id={obj.id}>
                    <div class="user-recharge">
                        <div class="time">{formatDate(obj.createTime)} <span>{obj.methodDesc}</span></div>
                        <ul class="money">
                            <li>充值金额：<span class="text">￥{obj.rechargeAmount}</span>
                                <span class="add"> 赠送 ${obj.giftAmount}</span>
                            </li>
                            {/*<li>充值前金额：*/}
                            {/*<span class="text">￥55.00</span>*/}
                            {/*</li>*/}
                            <li>余额：<span class="text">￥{obj.balance}</span></li>
                        </ul>
                    </div>
                </div>
            );
        };

        return (
            <Fragment>
                <PublicHeader title="充值记录"/>
                <ListView
                    ref={el => this.lv = el}
                    dataSource={dataSource.cloneWithRows(data)}
                    renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : '已经到底啦～'}
                    </div>)}
                    renderRow={row}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={4}
                    useBodyScroll
                    onScroll={() => {
                        console.log('scroll');
                    }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (data) => {
    return {
        userChargeList: data.userChargeList.userChargeList
    }
};

const mapDispatchToProps = (dispatch) => ({
    getUserChargeList: (pageIndex, userId) => dispatch(getUserChargeList({userId: userId, pageNo: pageIndex, size: 20}))
});


export default connect(mapStateToProps, mapDispatchToProps)(MyCharge);
