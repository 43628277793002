import React, {Component} from 'react';

class Area extends Component {
    render() {
        return (
            <div>sadfafdf</div>
        )

    }
}

export default Area;
