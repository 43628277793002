import React, {Component, Fragment, PureComponent} from 'react';

import {ListView, Button} from 'antd-mobile';
import {connect} from 'react-redux';
import {getOrderList} from './store/actionCreators';
import {formatDate} from '../../utils/common';
import history from './../../utils/history';

import PublicHeader from './../../components/header/index';

let pageIndex = 0;

class MyOrder extends PureComponent {

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
        };
    }

    componentDidMount() {
        let userInfos = Storage.Base.getInstance().get('userInfo');
        if (userInfos === null) {
            history.push('/home/login');
            return;
        }
        this.props.getOrderList(pageIndex, userInfos.id);

        setTimeout(() => {
            this.setState({
                isLoading: false,
            });
        }, 600);
    }

    onEndReached = (event) => {
        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        const {orderList} = this.props;
        const {data = [{}], total} = orderList;

        if (data.length >= total) {
            this.setState({hasMore: false});
        } else {
            setTimeout(() => {
                this.setState({hasMore: false}, () => {
                    this.currentPage = ++this.currentPage;
                    this.props.getOrderList(pageIndex).then(() => {
                        this.setState({hasMore: true});
                    });
                });
            }, 200);
        }
    }

    toPay = (orderId) => {
        console.log("orderId", orderId)
    }

    render() {
        const {orderList} = this.props;
        const {data = [{}]} = orderList;

        const separator = (sectionID, rowID) => (
            <div
                key={`${sectionID}-${rowID}`}
                style={{
                    backgroundColor: '#F5F5F9',
                    height: 8,
                    borderTop: '1px solid #ECECED',
                    borderBottom: '1px solid #ECECED',
                }}
            />
        );
        const renderRow = (rowData, rowID) => {
            if (JSON.stringify(rowData) == "{}") {
                return (
                    <div>
                        <div className="user-recharge" style={{textAlign: "center", padding: "15px", color: "#888"}}>
                            暂无订单信息
                        </div>
                    </div>);
            }
            const obj = rowData;
            const {siteDTO = {}, venueDTO = {}} = obj;
            return (
                <div key={rowID} style={{padding: '0 15px'}}>
                    <div
                        style={{
                            lineHeight: '30px',
                            color: '#888',
                            fontSize: 13,
                            borderBottom: '1px solid #F6F6F6',
                        }}
                    >{venueDTO.name}
                        {
                            obj.status == 1 ? (
                                <Button
                                    onClick={this.toPay({orderId: obj.id})}
                                    type="primary"
                                    inline
                                    size="small"
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        height: "24px",
                                        lineHeight: "24px",
                                        marginTop: "3px"
                                    }}>
                                    去支付</Button>) : ""
                        }

                    </div>
                    <div style={{display: '-webkit-box', display: 'flex', padding: '15px 0'}}>
                        <img style={{height: '64px', marginRight: '15px'}} src={obj.img} alt=""/>
                        <div style={{lineHeight: 1}}>
                            <div style={{marginBottom: '8px', fontWeight: 'bold'}}>
                                {siteDTO.name}
                            </div>
                            <div>
                                <span>{formatDate(obj.beginTime)}</span>
                                <span>~</span>
                                <span style={{marginLeft: "5px"}}>{formatDate(obj.endTime, 'HH:mm:ss')}</span>
                            </div>
                            <div style={{marginTop: "10px", lineHeight: "10px"}}>
                                <span style={{color: '#FF6E27'}}>{obj.price / 100}元</span>
                                <span style={{
                                    marginRight: "0px",
                                    marginLeft: '20px',
                                    fontSize: '10px',
                                    color: '#FF6E27',
                                    textAlign: 'right'
                                }}>{obj.status == 1 ? "待支付" : "已支付"}</span>
                                <span style={{
                                    marginLeft: '20px',
                                    color: '#888',
                                }}>{formatDate(obj.createTime)}</span>
                            </div>

                        </div>
                    </div>
                </div>
            );
        };

        return (
            <Fragment>
                <PublicHeader title="我的订单" bgColor="#51B5E7"/>
                <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource.cloneWithRows(data)}
                    renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : '已经到底啦～'}
                    </div>)}
                    renderRow={(rowData, id1, i) => renderRow(rowData, i)}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={4}
                    useBodyScroll
                    onScroll={() => {
                        console.log('scroll');
                    }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (data) => {
    return {
        orderList: data.orderList.orderList
    }
};

const mapDispatchToProps = (dispatch) => ({
    getOrderList: (pageIndex, userId) => dispatch(getOrderList({userId: userId, pageNo: pageIndex, size: 20})),
});
export default connect(mapStateToProps, mapDispatchToProps)(MyOrder);
