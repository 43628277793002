import * as constants from './constants';

const defaultState = {
    userInfo: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case constants.USER_INFO:
            return {...state, userInfo: action.value}
        default:
            return state;
    }
}